






























































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Permission} from '@/misc/enums/permission.enum';
import UserRole from '@/models/user-attributes/UserRole';
import Company from '@/models/Company';
import RJSelect from '@/components/shared/custom-vuetify/RJSelect.vue';
import RJAutocomplete from '@/components/shared/custom-vuetify/RJAutocomplete.vue';
import RoleManage from '@/components/role/RoleManage.component.vue';

const CompanyStore = namespace('company');
const UserStore = namespace('user');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    RJAutocomplete,
    RJSelect,
    RoleManage,
  },
})
export default class RoleDashboardComponent extends Vue {

  @UserStore.Action('loadUserRolesAction')
  private loadRolesAction!: (payload: { companyId: string, relations?: string[] }) => any;
  @UserStore.Action('deleteUserRoleAction')
  private deleteRoleAction!: (id: string) => any;
  @UserStore.Action('updateUserRoleAction')
  private updateRoleAction!: (role: UserRole) => UserRole;
  @CompanyStore.Getter('activeCompany')
  private company!: Company;

  @UserStore.Action('createUserRoleAction')
  private createRoleAction!: (role: UserRole) => any;

  private roles: UserRole[] = [];

  private showCreateDialog = false;
  private showDeleteDialog = false;

  // the role chosen from autocomplete
  private chosenRole: UserRole = new UserRole();
  // copy of chosen role that is update and compared with chosenRole to check for update
  private selectedRole: UserRole = new UserRole();

  @Watch('chosenRole')
  private updateSelectedRole() {
    this.selectedRole = this.chosenRole.copy() as UserRole;
    this.selectedRole.permissions = [...this.chosenRole.permissions];
  }

  private get isRoleChanged() {
    return JSON.stringify(this.chosenRole) === JSON.stringify(this.selectedRole);
  }

  private created() {
    this.loadRoles();
    this.reset();
  }

  private async loadRoles() {
    this.roles = await this.loadRolesAction({companyId: this.$route.params.companyId});
    if (this.roles.length) {
      this.chosenRole = this.roles[0];
    }
  }

  private async updateExistingRole() {
    if (!this.selectedRole.name || this.selectedRole.name.trim().length === 0) {
      this.$notifyErrorSimplified('ROLE_MANAGE.ROLE_NEEDS_NAME');
      return;
    }
    try {
      this.chosenRole = await this.updateRoleAction(this.selectedRole);
      this.$notifySuccessSimplified('ROLE_MANAGE.NOTIFICATIONS.EDIT.SUCCESS');
    } catch (error: any) {
      this.$notifyErrorSimplified('ROLE_MANAGE.NOTIFICATIONS.EDIT.ERROR');
    }
  }

  private async deleteRole() {
    try {
      await this.deleteRoleAction(this.selectedRole.id);
      this.showDeleteDialog = false;
      this.chosenRole = this.roles[0];
      this.$notifySuccessSimplified('ROLE_MANAGE.NOTIFICATIONS.DELETE.SUCCESS');
    } catch (error: any) {
      this.$notifyErrorSimplified('ROLE_MANAGE.NOTIFICATIONS.DELETE.ERROR');
    }
  }

  private newRole: UserRole = new UserRole();

  private reset() {
    this.newRole.name = '';
    this.newRole.draft = false;
    this.newRole.global = false;
    this.newRole.companyId = this.company.id;
    this.newRole.isLocationManager = false;
    this.newRole.permissions = [];
  }

  private async submitNewRole() {
    if (!this.newRole.name || this.newRole.name.trim().length === 0) {
      this.$notifyErrorSimplified('ROLE_MANAGE.ROLE_NEEDS_NAME');
      return;
    }
    try {
      // display the new role
      this.chosenRole = await this.createRoleAction(this.newRole);
      // push new role to roles array
      this.roles.push(UserRole.parseFromObject(this.chosenRole));
      this.showCreateDialog = false;
      this.reset();
      this.$notifySuccessSimplified('ROLE_MANAGE.NOTIFICATIONS.CREATE.SUCCESS');
    } catch (error: any) {
      this.$notifyErrorSimplified('ROLE_MANAGE.NOTIFICATIONS.CREATE.ERROR');
    }
  }
}
